<script setup>
import LanguageSwitcher from "@/Components/LanguageSwitch/LanguageSwitcher.vue";
import NavDesktop from "@/Components/Navigation/NavDesktop.vue";
import NavMobile from "@/Components/Navigation/NavMobile.vue";
import { Head, usePage } from "@inertiajs/vue3";
import { computed, onMounted, ref, useSlots } from "vue";
import { PhoneIcon } from "@heroicons/vue/24/outline/index.js";
import { PhoneIcon as SolidPhoneIcon } from "@heroicons/vue/24/solid/index.js";

const props = defineProps({
    title: String,
});

const slots = useSlots();

const page = usePage();

onMounted(() => {
    document.documentElement.style.setProperty("--primary-color", page.props.colors.primary);
    document.documentElement.style.setProperty("--primary-light-color", page.props.colors.primaryLight);
    document.documentElement.style.setProperty("--primary-dark-color", page.props.colors.primaryDark);
});

const pageTitle = computed(() => {
    return `${props.title} - ${page.props.currentTenant.name}`;
});

const showPhone = ref(false);
</script>

<template>
    <Head :title="pageTitle" />

    <div id="all_content" class="text-gray-700 min-h-screen box-border pt-20">
        <header class="fixed w-full z-40 top-0 left-0 bg-white shadow-lg">
            <div class="h-20 max-w-5xl lg:container mx-auto w-full px-4">
                <div class="h-full flex justify-between items-center">
                    <NavMobile />
                    <img
                        :src="$page.props.currentTenant.logo_path"
                        alt="Logo"
                        class="h-full max-w-[200px] w-fit py-4 flex items-center"
                    />
                    <div class="flex items-center gap-4 lg:gap-8 xl:gap-8">
                        <NavDesktop />
                        <div class="group shrink-0 relative hidden h-20 xl:flex items-center">
                            <button @click.prevent="showPhone = !showPhone">
                                <PhoneIcon class="size-6 group-hover:text-theme-primary duration-300" />
                            </button>
                            <transition>
                                <a
                                    v-if="showPhone"
                                    :href="'tel:' + page.props.currentTenant.phone"
                                    class="absolute max-w-xs bg-white rounded-full items-center font-medium text-lg shadow-md w-max p-2 pr-4 flex gap-4 top-full mt-2 left-[50%] -translate-x-[50%]"
                                >
                                    <span
                                        class="size-7 text-white bg-theme-primary flex justify-center items-center rounded-full"
                                    >
                                        <SolidPhoneIcon class="size-4" />
                                    </span>

                                    <span>
                                        {{ page.props.currentTenant.phone }}
                                    </span>
                                </a>
                            </transition>
                        </div>

                        <LanguageSwitcher />
                    </div>
                </div>
            </div>
        </header>

        <slot name="banner"></slot>

        <!-- Page Content -->
        <main class="max-w-5xl lg:container mx-auto py-8 lg:py-16 px-4" :class="slots.banner ? '' : 'mt-16'">
            <slot />
        </main>
    </div>
</template>
