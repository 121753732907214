<script setup>
import { Bars3Icon } from "@heroicons/vue/24/solid/index.js";
import NavItems from "@/Components/Navigation/NavItems.vue";
import { XMarkIcon } from "@heroicons/vue/24/solid/index.js";
import { ref } from "vue";

const emit = defineEmits(["close"]);

const showNav = ref(false);

//default value for page reload
document.getElementById("body").style.overflow = "auto";
function show() {
    showNav.value = true;
    document.getElementById("body").style.overflow = "hidden";
}
function hide() {
    showNav.value = false;
    document.getElementById("body").style.overflow = "auto";
}
</script>

<template>
    <div class="xl:hidden flex items-center">
        <button @click="show" v-if="showNav === false">
            <Bars3Icon class="w-10 h-10 text-theme-primary" />
        </button>
        <button @click="hide" v-else>
            <XMarkIcon class="w-10 h-10 text-theme-primary" />
        </button>
    </div>

    <transition>
        <div class="fixed top-20 border-t-2 border-t-theme-primary left-0 inset-0 bg-white" v-if="showNav">
            <nav class="px-4 py-6">
                <ul class="text-2xl font-bold flex flex-col divide-y">
                    <NavItems />
                </ul>
            </nav>
        </div>
    </transition>
</template>

<style scoped></style>
